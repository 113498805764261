<template>
  <div >
    <settingsComponent />
  </div>
</template>

<script>


import settingsComponent from '@/components/settingsComponent.vue'


export default {
  name: 'Settings',
  components: {
    settingsComponent,
  },
}
</script>